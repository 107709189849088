import { Grid } from '@elliemae/ds-grid';
import { PageHeaderTitle } from '@elliemae/ds-page-header';
import { DSPageHeaderV2 } from '@elliemae/ds-page-header';

interface HeaderProps {
  titleText: string;
}
export const CommonHeader: React.FC<HeaderProps> = ({ titleText }) => {
  const today = new Date();
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(today);

  return (
    <Grid style={{ minHeight: '48px' }}>
      <DSPageHeaderV2
        pageTitle={
          <PageHeaderTitle>
            {titleText}{' '}
            <span style={{ position: 'absolute', right: '0' }}>
              {formattedDate}
            </span>
          </PageHeaderTitle>
        }
      />
    </Grid>
  );
};
