import { Grid } from '@elliemae/ds-grid';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import Header from './global-header';

export const PricingSearchLayout = () => {
  // useInjectReducer({ key: 'loans', reducer });
  // useInjectSaga({ key: 'loans', saga: loanSagas });

  return (
    <>
      <Helmet defaultTitle="ICE Rate Management Application">
        <meta name="description" content="ICE Rate Management Application" />
      </Helmet>
      <Grid
        style={{ width: '100%' }}
        height="100vh"
        rows={['auto', '1fr']}
        data-testid="page-layout"
      >
        {window.self === window.top && (
          <Grid>
            <Header />
          </Grid>
        )}
        <Grid>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};
