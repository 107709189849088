import {
  ChevronItem,
  LeftNavigation as DSLeftNavigation,
  DSLeftNavigationT,
  ITEM_TYPES,
} from '@elliemae/ds-left-navigation';
import { useState, useCallback } from 'react';
import { Forms } from '@elliemae/ds-icons';
import { useNavigate } from 'react-router-dom';

interface CallbackParams {
  navigate: ReturnType<typeof useNavigate>;
}

type ItemWithCallback = DSLeftNavigationT.GenericItemItemProps & {
  onOpen?: (params: CallbackParams) => void;
};

const navItems: ItemWithCallback[] = [
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'global-configuration-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Global Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/global-configuration`);
    },
  },

  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'investor-configuration-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Investor Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/investor-configuration`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'client-configuration-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Sheet Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/client-configuration`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'rate_definitions-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Definitions',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/rate-definitions`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'programFlagId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Program Flags',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open program flags');
      params.navigate(`/program-flags`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'client-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Clients',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('open clients Page');
      params.navigate(`/clients`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'rateDataId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Data',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open Rate Data');
      params.navigate(`/rate-data`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'programsId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Programs',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Programs');
      params.navigate(`/programs`);
    },
  },
  // Add other navigation items here
];

export const LeftNavigation = () => {
  const [expanded, setExpanded] = useState(true);
  const [openedItem, setOpenedItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const onOpenParams: CallbackParams = {
    navigate,
  };

  const onItemClickHandler = useCallback(
    async (item: ItemWithCallback) => {
      if (!item.dsId || openedItem === item.dsId) {
        console.log('closed item');
        setOpenedItem(null);
        setExpanded(false);
        return;
      }

      setOpenedItem(item.dsId);

      if (item.onOpen) {
        console.log('open item');
        setExpanded(true);
        item.onOpen(onOpenParams);
        return;
      }

      setExpanded(true);
    },
    [onOpenParams, openedItem],
  );

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    if (!openedItem) {
      setExpanded(false);
    }
  };

  return (
    <DSLeftNavigation
      expanded={expanded}
      items={navItems}
      onFooterExpand={() => setExpanded(true)}
      onClick={() => setExpanded(true)}
      onFooterClose={() => setExpanded(false)}
      onItemClick={onItemClickHandler}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    />
  );
};
