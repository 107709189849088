import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AuthenticationService } from '../../Auth/loginservice';

type MFAFormProps = {
  mfaToken: string;
  onSuccess: () => void;
};

type FormData = {
  mfaCode: string;
};

const MFAForm: React.FC<MFAFormProps> = ({ mfaToken, onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [mfaError, setMfaError] = useState<string | null>(null);
  const service = new AuthenticationService();

  const onSubmit = async (data: FormData) => {
    try {
      const token = await service.loginWithMFACode(mfaToken, data.mfaCode);
      console.log('Access Token:', token); // Log the fetched access token
      onSuccess();
    } catch (error) {
      setMfaError(
        'MFA verification failed. Please check your code and try again.',
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>MFA Code</label>
          <input
            {...register('mfaCode', {
              required: true,
              minLength: 6,
              maxLength: 6,
            })}
          />
          {errors.mfaCode && <span>MFA code must be 6 digits</span>}
        </div>
        <button type="submit">Verify</button>
        {mfaError && <p>{mfaError}</p>}
      </form>
    </div>
  );
};

export default MFAForm;
