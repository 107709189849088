import { getAuthHTTPClient } from '@elliemae/pui-app-sdk';
import { API_URL } from '../api/config'
const baseUrl = API_URL;

export class AuthenticationService {
  client: ReturnType<typeof getAuthHTTPClient>;

  constructor() {
    this.client = getAuthHTTPClient();
  }

  async login(creds: { emailAddress: string; password: string }) {
    try {
      const response = await this.client.post(
        `${baseUrl}/users/authenticate`,
        creds,
      );
      console.log(`login response`, response);
      return response;
    } catch (err: any) {
      if (err?.response && err?.response?.status === 403) {
        console.log('catching MFA error. Continuing');
        return err.response;
      }
      throw err;
    }
  }

  async loginWithMFACode(mfaResponseUuid: string, mfaCode: string) {
    try {
      const response = await this.client.post(
        `${baseUrl}/users/multi-factor/verify/${mfaResponseUuid}?SILENT_ERROR`,
        { code: mfaCode },
      );
      const token = response.data.item.sessionState.accessToken as string;
      this.client.interceptors.request.use((config) => {
        config.headers['X-Access-Token'] = token;
        return config;
      });
      return token;
    } catch (error) {
      console.error('MFA Verification failed', error);
      throw error;
    }
  }
}
