import { sendBAEvent } from '@elliemae/pui-app-sdk';

export const newLoanStartEvent = (loanId: string) => {
  sendBAEvent({
    data: {
      event: 'newLoanStart',
      loanId,
    },
    self: false,
  });
};

export const loanSaveEvent = (loanId: string) => {
  sendBAEvent({
    data: {
      event: 'loanSave',
      loanId,
    },
    self: false,
  });
};
