import React from 'react';
import { CommonHeader } from '../common/headerUtil';

const GuidelineVersions = () => {
  return (
    <>
      <CommonHeader titleText="Guideline Versions" />
      {/* Your component content goes here */}
      <h1>Guideline Versions</h1>
    </>
  );
};

export default GuidelineVersions;
