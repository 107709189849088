/**
 * Asynchronously loads the component for FeaturePage
 */
import { loadable } from '@elliemae/pui-app-sdk';

const LoadableClientConfiguration = loadable(
  () =>
    import(
      /* webpackChunkName: "client-configuration" */ /* webpackPrefetch: true */ './index'
    ),
);

export default LoadableClientConfiguration;
