/**
 * Asynchronously loads the component for FeaturePage
 */

import { loadable } from '@elliemae/pui-app-sdk';

export const LoadableGlobalConfiguration = loadable(
  () =>
    import(
      /* webpackChunkName: "global-configuration" */ /* webpackPrefetch: true */ './index'
    ),
);

export default LoadableGlobalConfiguration;
