import React from 'react';
import { CommonHeader } from '../common/headerUtil';

const ConditionVersions = () => {
  return (
    <>
      <CommonHeader titleText="Condition Versions" />
      {/* Your component content goes here */}
      <h1>Condition Versions</h1>
    </>
  );
};

export default ConditionVersions;
