import {
  call,
  put,
  takeLatest,
  StrictEffect,
  ForkEffect,
} from 'redux-saga/effects';
import { CMicroAppGuest, error } from '@elliemae/pui-app-sdk';
import { loans } from '../../data/loans';
import { createLoan, CreateLoanResponse } from '../../api/loans';
import { getHomePath } from '../../utils/paths';

type CreateLoanHandlerReturnType = Generator<
  StrictEffect,
  void,
  CreateLoanResponse
>;

function* createLoanHandler(): CreateLoanHandlerReturnType {
  try {
    const app = CMicroAppGuest.getInstance();
    const { loanId } = yield call(createLoan);
    app.navigate(`${getHomePath()}/1003/${loanId}`);
  } catch (err) {
    yield put(error.set({ messageText: (err as Error)?.message }));
  }
}

export function* onCreateLoan(): Generator<ForkEffect, void, unknown> {
  yield takeLatest(loans.create.type, createLoanHandler);
}
