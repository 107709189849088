import { GetLoansResponse } from '../../api/loans';

export const toUIModel = (loans: Array<GetLoansResponse>) =>
  loans.map((loanInfo) => ({
    loanId: loanInfo.id,
    firstname: loanInfo.firstname,
    lastname: loanInfo.lastname,
    dob: loanInfo.dob,
    maritalstatus: loanInfo.maritalstatus,
    city: loanInfo?.address?.city,
    state: loanInfo?.address?.state,
  }));
