export const logRecords = {
  LOAN_GET_FAILED: {
    errorCode: 'microapp001',
    message: 'Unable to get list of loans',
  },
  LOAN_CREATE_FAILED: {
    errorCode: 'microapp002',
    message: 'Unable to create loan',
  },
  LOAN_SAVE_FAILED: {
    errorCode: 'microapp003',
    message: 'Unable to save loan data',
  },
  SAGA_INIT_ERROR: {
    errorCode: 'microapp004',
    message: 'Unable to execute sideeffects for the application',
  },
};
