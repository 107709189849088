import { loadable } from '@elliemae/pui-app-sdk';

export const LoadableSchemaView = loadable(
  () =>
    import(
      /* webpackChunkName: "schema-view" */ /* webpackPrefetch: true */ '.'
    ),
  null, // use wait message from html
);

export default LoadableSchemaView;
