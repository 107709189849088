import { loadable } from '@elliemae/pui-app-sdk';

export const RateDefinitions = loadable(
  () =>
    import(
      /* webpackChunkName: "datapage1" */ /* webpackPrefetch: true */ './index'
    ),
);

export const EditPage = loadable(
   () =>
     import(
       /* webpackChunkName: "editPage" */ /* webpackPrefetch: true */ './EditPage/EditPage'
     ),
 
 );

 export const RateDefinitionsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "editPage" */ /* webpackPrefetch: true */ './EditPage/RateDefinitionsEdit'
    ),
 );