import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '@elliemae/pui-app-sdk';
import { Grid } from '@elliemae/ds-grid';
import Header from './global-header';
import { reducer } from '../../data/loans';
import { loanSagas } from '../../sideeffect/loans';
import { LeftNavigation } from '../left-navigation';

export const Layout = () => {
  useInjectReducer({ key: 'loans', reducer });
  useInjectSaga({ key: 'loans', saga: loanSagas });

  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  return (
    <>
      <Helmet defaultTitle="ICE Rate Management Application">
        <meta name="description" content="ICE Rate Management Application" />
      </Helmet>
      <Grid
        style={{ width: '100%' }}
        height="100vh"
        rows={['auto', '1fr']}
        data-testid="page-layout"
      >
        {window.self === window.top && (
          <Grid>
            <Header />
          </Grid>
        )}
        <Grid cols={['min-content', '1fr']}>
          {!isLoginPage && <LeftNavigation />}
          <Grid rows={['min-content', '1fr']}>
            {/* <div>
              <ul>
               
              </ul>
            </div> */}

            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
