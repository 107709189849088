import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeLatest,
  ForkEffect,
  StrictEffect,
} from 'redux-saga/effects';
import {
  waitMessageAction,
  error,
  CMicroAppGuest,
} from '@elliemae/pui-app-sdk';
import { loans } from '../../data/loans';
import { saveLoan, SaveLoanResponse, LoanRecord } from '../../api/loans';
import { getHomeRoute } from '../../utils/paths';
import { loanSaveEvent } from '../../analytics/loan';

type SaveLoanHandlerReturnType = Generator<
  StrictEffect,
  void,
  SaveLoanResponse
>;

function* onSaveLoanHandler({
  payload,
}: PayloadAction<LoanRecord>): SaveLoanHandlerReturnType {
  try {
    const app = CMicroAppGuest.getInstance();
    yield put(waitMessageAction.open());
    yield call(saveLoan, payload);
    yield put(loans.saveSuccess(payload));
    yield call(loanSaveEvent, payload.loanId);
    app.navigate(getHomeRoute());
  } catch (err) {
    yield put(error.set({ messageText: (err as Error)?.message }));
  } finally {
    yield put(waitMessageAction.close());
  }
}

export function* onSaveLoan(): Generator<ForkEffect, void, unknown> {
  yield takeLatest(loans.save.type, onSaveLoanHandler);
}
