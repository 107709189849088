import { createSlice, PayloadActionCreator } from '@reduxjs/toolkit';
import { getApiActionCreator } from '@elliemae/pui-app-sdk';
import { LoanRecord, CreateLoanResponse } from '../../api/loans';

const loansActionCreator = getApiActionCreator('loans');

type GetLoanActions = {
  get: PayloadActionCreator<void, string>;
  getSuccess: PayloadActionCreator<Array<LoanRecord>, string>;
  getError: PayloadActionCreator<void, string>;
};

type CreateLoanActions = {
  create: PayloadActionCreator<void, string>;
  createSuccess: PayloadActionCreator<CreateLoanResponse, string>;
  createError: PayloadActionCreator<void, string>;
};

type SaveLoanActions = {
  save: PayloadActionCreator<LoanRecord, string>;
  saveSuccess: PayloadActionCreator<LoanRecord, string>;
  saveError: PayloadActionCreator<void, string>;
};

export const loans = {
  ...(loansActionCreator('create') as unknown as CreateLoanActions),
  ...(loansActionCreator('get') as unknown as GetLoanActions),
  ...(loansActionCreator('save') as unknown as SaveLoanActions),
};

const loansSlice = createSlice({
  name: 'loans',
  initialState: [] as Array<LoanRecord>,
  reducers: {
    getSuccess: (state, { payload }: { payload: Array<LoanRecord> }) => {
      state.push(...payload);
    },
    saveSuccess: (state, { payload }: { payload: LoanRecord }) => {
      state.push(payload);
    },
  },
});

export const { reducer } = loansSlice;
