/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useCallback, useMemo } from 'react';
import { Grid } from '@elliemae/ds-grid';
import {
  DSGlobalHeader,
  type DSGlobalHeaderInternalsT,
} from '@elliemae/ds-global-header';
import { LoginUser, ENCDarkIceBlue, ENCIceBlue } from '@elliemae/ds-icons';
import { useNavigate } from 'react-router-dom';

const useUserMenu = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const menuOpts3 = useMemo(
    () => [
      {
        label: 'Logout',
        onClick: () => {
          sessionStorage.removeItem('Authorization'); // Clear session storage
          localStorage.removeItem('selectedLenderId');
          localStorage.removeItem('selectedInvestor');
          navigate('/login'); // Navigate to the login page
        },
        'data-testid': 'item-1',
      },
    ],
    [navigate],
  );

  return useMemo(
    () => ({
      id: 'menu-3',
      type: 'ds-popup-menu',
      label: 'Login User',
      componentProps: {
        options: menuOpts3,
        closeOnClick: true,
      },
      Icon: LoginUser,
      onClick: () => null,
    }),
    [menuOpts3],
  );
};

const useBreadcrumb = () => {
  const [breadcrumbSelected, setBreadcrumbSelected] = useState('');

  const handleBreadcrumbClick = useCallback(
    (item: string) => () => {
      setBreadcrumbSelected(item);
    },
    [],
  );

  return useMemo(
    () =>
      [
        {
          label: 'ICE PPE',
          hasNext: false,
        },
      ] as DSGlobalHeaderInternalsT.BreadcrumbItem[],
    [breadcrumbSelected, handleBreadcrumbClick],
  );
};
const useToolbar = () => {
  const popupMenu3 = useUserMenu();
  const toolbar = useMemo(() => [popupMenu3], [popupMenu3]);
  return useMemo(() => ({ toolbar }), [toolbar]);
};

export const AppGlobalHeader = () => {
  const { toolbar } = useToolbar();
  const breadcrumb = useBreadcrumb();

  return (
    <>
      <Grid style={{ minWidth: '570px' }}>
        <DSGlobalHeader
          Logo={ENCIceBlue}
          LogoWithBrand={ENCDarkIceBlue}
          breadcrumb={breadcrumb}
          toolbar={toolbar}
        />
      </Grid>
    </>
  );
};

export default AppGlobalHeader;
