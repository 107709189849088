import {
  call,
  put,
  takeLatest,
  ForkEffect,
  StrictEffect,
} from 'redux-saga/effects';
import { waitMessageAction, error } from '@elliemae/pui-app-sdk';
import { loans } from '../../data/loans';
import { toUIModel } from '../../data/loans/convert';
import { getLoans, GetLoansResponse } from '../../api/loans';

type GetAllLoansHandlerReturnType = Generator<
  StrictEffect,
  void,
  Array<GetLoansResponse>
>;

function* getAllLoans(): GetAllLoansHandlerReturnType {
  try {
    yield put(waitMessageAction.open());
    const loansList = yield call(getLoans);
    yield put(loans.getSuccess(toUIModel(loansList)));
  } catch (err) {
    yield put(error.set({ messageText: (err as Error)?.message }));
  } finally {
    yield put(waitMessageAction.close());
  }
}

export function* onGetAllLoans(): Generator<ForkEffect, void, unknown> {
  yield takeLatest(loans.get.type, getAllLoans);
}
