import { all, fork } from 'redux-saga/effects';
import { logger } from '../../utils/logger';
import { onGetAllLoans } from './get';
import { onSaveLoan } from './save';
import { onCreateLoan } from './create';
import { logRecords } from '../../logRecords';

export function* loanSagas() {
  try {
    yield all([fork(onCreateLoan), fork(onGetAllLoans), fork(onSaveLoan)]);
  } catch (err) {
    logger.error({ ...logRecords.SAGA_INIT_ERROR, exception: err as Error });
  }
}
