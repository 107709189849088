import {
  call,
  put,
  takeLatest,
  ForkEffect,
  StrictEffect,
} from 'redux-saga/effects';
import {
  auth,
  globalConstants,
  getRedirectUrl,
  error,
  logout,
} from '@elliemae/pui-app-sdk';

import { endSession } from '../../api/session';

function* endSessionHandler(): Generator<StrictEffect, void, void> {
  try {
    const cred = sessionStorage.getItem('Authorization') || '';
    yield call(endSession, {
      token: cred,
    });
    // logout
    yield put(
      auth.logout({
        clientId: globalConstants.CLIENT_ID,
        redirectUri: getRedirectUrl(),
        responseType: 'code',
        scope: 'loc',
        code: '1004',
      }),
    );
  } catch (err) {
    yield put(error.set({ messageText: (err as Error)?.message }));
  }
}

export function* onSessionEvent(): Generator<ForkEffect, void, unknown> {
  yield takeLatest(logout.CONFIRM, endSessionHandler);
}
