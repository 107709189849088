import { getAuthHTTPClient } from '@elliemae/pui-app-sdk';
import { logger } from '../../utils/logger';
import { logRecords } from '../../logRecords';

const basePath = '/v1/loans';

export interface GetLoansResponse {
  id: string;
  firstname: string;
  lastname: string;
  dob: string;
  maritalstatus: string;
  address: {
    string: string;
    city: string;
    state: string;
    zipcode: number;
  };
}

export const getLoans = async () => {
  try {
    const { data }: { data: Array<GetLoansResponse> } =
      await getAuthHTTPClient().get(basePath);
    return data;
  } catch (err) {
    logger.error({ ...logRecords.LOAN_GET_FAILED, exception: err as Error });
    throw new Error(
      `Unable to get list of loans. Please try again later. more details:${
        (err as Error)?.message
      }`,
    );
  }
};

export interface CreateLoanResponse {
  loanId: string;
}

export const createLoan = async () => {
  try {
    const { data }: { data: CreateLoanResponse } =
      await getAuthHTTPClient().put(`${basePath}/create`);
    return data;
  } catch (err) {
    logger.error({ ...logRecords.LOAN_CREATE_FAILED, exception: err as Error });
    throw new Error(
      `Unable to create new loan. Please try again later. more details:${
        (err as Error)?.message
      }`,
    );
  }
};

export interface SaveLoanResponse {
  loanId: string;
}

export interface LoanRecord {
  loanId: string;
  firstname: string;
  lastname: string;
  dob: string;
  maritalstatus: string;
  state: string;
  city: string;
}

export const saveLoan = async ({ loanId, ...loanFields }: LoanRecord) => {
  try {
    const { data }: { data: SaveLoanResponse } = await getAuthHTTPClient().post(
      `${basePath}/${loanId}/save`,
      loanFields,
    );
    return data;
  } catch (err) {
    logger.error({ ...logRecords.LOAN_SAVE_FAILED, exception: err as Error });
    throw new Error(
      `Unable to save loan data. Please try again later. more details:${
        (err as Error)?.message
      }`,
    );
  }
};
