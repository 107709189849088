import React from 'react';
import { CommonHeader } from '../common/headerUtil';

const RatioVersions = () => {
  return (
    <>
      <CommonHeader titleText="Ratio Versions" />
      {/* Your component content goes here */}
      <h1>Ratio Versions</h1>
    </>
  );
};

export default RatioVersions;
