import { loadable } from '@elliemae/pui-app-sdk';

export const Clients = loadable(
  () =>
    import(
      /* webpackChunkName: "datapage1" */ /* webpackPrefetch: true */ './index'
    ),
);

export const ClientDefinition = loadable(
  () =>
    import(
      /* webpackChunkName: "editPage" */ /* webpackPrefetch: true */ './clientDefinition'
    ),
 );