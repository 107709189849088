export const addTrailingSlash = (path: string) => path.replace(/\/?$/, '/');
export const removeTrailingSlash = (path: string) => path.replace(/\/?$/, '');

export const getBasePath = () =>
  window.emui?._BASE_PATH
    ? removeTrailingSlash(window.emui._BASE_PATH) || '/'
    : '/';

let homeRoute = getBasePath();
export const setHomeRoute = (path = getBasePath()) => {
  homeRoute = removeTrailingSlash(path) || '/';
};
export const getHomeRoute = () => homeRoute;
export const getHomePath = () => removeTrailingSlash(homeRoute);
